export default defineNuxtPlugin(async (nuxtApp) => {
  function piniaFetch(context) {
    return {
      $recaptcha: context.app.config.globalProperties.$recaptcha,
      $analytics: context.app.config.globalProperties.$analytics,
      $toastError: context.app.config.globalProperties.$toastError,
      $clerk: context.app.config.globalProperties.$clerk,
    };
  }

  nuxtApp.$pinia.use(piniaFetch);
});
