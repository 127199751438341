export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const modal = document.createElement("div");
  modal.id = "rsk-modal";
  modal.className =
    "fixed left-0 top-0 min-h-screen bg-black/50 flex justify-center items-center hidden";
  modal.style.zIndex = "120";
  modal.style.minWidth = "100vw";

  const card = document.createElement("div");
  card.className = "p-4 bg-white rounded-md";

  const iframe = document.createElement("iframe");
  iframe.style.height = "182px";
  iframe.id = "rsk-iframe";
  iframe.src = runtimeConfig.public.RSK_URL;
  card.appendChild(iframe);

  modal.appendChild(card);
  document.body.appendChild(modal);
});
