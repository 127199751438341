import { useWalletStore } from "~/store/wallet";

export default defineNuxtPlugin(() => {
  const walletStore = useWalletStore();
  window.addEventListener("message", async (event) => {
    const web3modal_iframe = document.getElementById("web3modal_iframe");
    if (["modal_closed", "account_changed"].includes(event.data?.type)) {
      if (event.data.payload) {
        walletStore.walletAddress = event.data.payload.address;
        walletStore.walletType = event.data.payload.type;
        walletStore.isActive = true;
      } else {
        walletStore.walletAddress = null;
        walletStore.walletType = null;
        walletStore.isActive = false;
      }
      if (event.data.type === "modal_closed") {
        setTimeout(() => {
          web3modal_iframe.classList.add("hidden");
        }, 400);
      }
    }
  });
});
