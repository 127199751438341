import { api } from "@/utils/api";
import { pick } from "@/utils/helpers";

export function companies() {
  return api.get("/companies");
}

export function getWebhooks(id) {
  return api.get(`/companies/${id}/webhook`);
}

export function addWebhook(id, endpoint) {
  return api.post(`/companies/${id}/webhook`, { endpoint });
}

export function getConfig(id) {
  return api.get(`/companies/${id}/config`);
}

export function putConfig(id, data) {
  const whitelist = "payee_invoices,allow_payee_anonymous";
  return api.put(`/companies/${id}/config`, pick(data, whitelist));
}

export function getCompanyById(params) {
  const whitelist = "id";
  return api.get(`/companies/${params.id}`, pick(params, whitelist));
}

export function create(params) {
  const whitelist =
    "name,size,incorporation,incorporationType,website,linkedin,phone,country,address1,address2,city,state,zip,tax_id";
  return api.post("/companies/", pick(params, whitelist));
}

export function createTeam(params) {
  const whitelist =
    "name,team,uuid,incorporation,incorporationType,website,phone,country,address1,address2,city,state,zip,tax_id,address,signature";
  return api.post("/teams/", pick(params, whitelist));
}

export function update(params) {
  const whitelist =
    "incorporationType,website,linkedin,phone,address1,address2,city,state,zip,disableSOW";
  return api.put(`/companies/${params.id}`, pick(params, whitelist));
}

export function enablePSALite(id, enable) {
  return api.post(`/companies/${id}/psa-lite`, { enable });
}

export function importTalent(params, recaptchaToken) {
  const whitelist = "emailList,isAnonymous";
  return api.post(
    `/talent-imports/?recaptchaToken=${recaptchaToken}`,
    pick(params, whitelist)
  );
}

export function avatarSignedUrl() {
  return api.get("/companies/avatar-upload");
}

export function updateAvatar(fileName) {
  return api.post("/companies/update-avatar", { fileName });
}

export function getContractors({
  companyId,
  cursor,
  text,
  status,
  withdraw_fee_coverage,
  limit,
}) {
  const params = new URLSearchParams();

  if (limit) params.append("limit", limit);
  if (cursor) params.append("cursor", cursor);
  if (text && text.length > 0) params.append("text", text);
  if (status) params.append("status", status);
  if (withdraw_fee_coverage !== undefined && withdraw_fee_coverage !== null) {
    params.append("withdraw_fee_coverage", withdraw_fee_coverage);
  }

  return api.get(`/companies/${companyId}/talent?${params.toString()}`);
}

export function getContractorsByStatus({ companyId, status }) {
  return api.get(`/companies/${companyId}/talent?status=${status}`);
}

export function getPermissionsRequested(id) {
  return api.get(`/companies/${id}/permissions-requested`);
}

export function companyKyb(companyId, checks) {
  return api.post(`/companies/${companyId}/kyb`, { checks });
}

export function getManagerWalletRequests(managerId) {
  return api.get(`/companies/managers/wallet-requests?managerId=${managerId}`);
}

export function removeManager(managerId) {
  return api.post(`/companies/managers/remove?managerId=${managerId}`);
}

export function batchPaymentMessage({ companyId, wallet, payments }) {
  return api.put(`/companies/${companyId}/payments/batch`, {
    wallet,
    payments,
  });
}

export function batchPayment({
  companyId,
  wallet,
  payments,
  salt,
  request,
  signature,
}) {
  return api.post(`/companies/${companyId}/payments/batch`, {
    wallet,
    payments,
    salt,
    request,
    signature,
  });
}

export function feesDue(companyId) {
  return api.get(`/companies/${companyId}/fees/due`);
}

export function getFees(companyId) {
  return api.get(`/companies/${companyId}/fees`);
}

export function putFees({ companyId, wallet }) {
  return api.put(`/companies/${companyId}/payments/fees`, { wallet });
}

export function postFees(companyId, body) {
  return api.post(`/companies/${companyId}/payments/fees`, body);
}

export default {
  create,
  createTeam,
  getCompanyById,
  update,
  enablePSALite,
  companies,
  importTalent,
  getContractors,
  getContractorsByStatus,
  getPermissionsRequested,
  companyKyb,
  removeManager,
  getManagerWalletRequests,
  batchPaymentMessage,
  batchPayment,
  feesDue,
  getFees,
  putFees,
  postFees,
  getWebhooks,
  addWebhook,
};
