import { defineStore } from "pinia";

export const useWalletNotAllowedStore = defineStore("walletNotAllowed", {
  state: () => ({
    isOpened: false,
  }),

  actions: {
    setOpened(value) {
      this.isOpened = value;
    },
  },
});
