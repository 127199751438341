import { api } from "@/utils/api";

export function updateDelegate(id, params) {
  return api.put(`/rise-id-delegates/${id}`, params);
}
export function getDelegates() {
  return api.get("/rise-id-delegates");
}

export default {
  updateDelegate,
  getDelegates,
};
