<script setup>
const props = defineProps({
  title: String,
  description: {
    type: String,
    default: "",
  },
  variant: {
    type: String,
    default: "danger",
  },
  buttons: Array,
  time: Number,
});
const emit = defineEmits(["close"]);

const variants = {
  success: "!text-green-500 bg-white border-green-300",
  danger: "!text-red-500 bg-white border-red-300",
};

const progressBgVariants = {
  success: "bg-green-400 h-0.5 rounded-b-full",
  danger: "bg-red-400 h-0.5 rounded-b-full",
};

function closeWhenTimeExpires() {
  if (props.time > 0) {
    setTimeout(() => {
      emit("close");
    }, props.time);
  }
}

const timeStart = ref(1000);
const timeCurrent = ref(0);
const timeInterval = ref(null);
onMounted(() => {
  timeStart.value = Date.now();
  timeInterval.value = setInterval(() => {
    timeCurrent.value = Date.now();
  }, 1);
  closeWhenTimeExpires();
});

const progress = computed(() => {
  return ((timeCurrent.value - timeStart.value) * 100) / props.time;
});

closeWhenTimeExpires();
</script>

<template>
  <div
    class="relative w-full rounded-md border shadow-sm"
    :class="[variants[variant]]"
    role="alert"
  >
    <div
      class="absolute right-[8px] pt-2 px-2 top-[-5px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-lg hover:bg-gray-100"
      @click="$emit('close')"
    >
      <font-awesome-icon icon="xmark" />
    </div>
    <div class="flex items-center pt-2 px-2 mb-2">
      <font-awesome-icon
        v-if="variant === 'success'"
        icon="circle-check"
        class="mr-2 h-[16px] w-[16px]"
      />
      <font-awesome-icon
        v-if="variant === 'danger'"
        icon="circle-xmark"
        class="mr-2 h-[16px] w-[16px]"
      />
      <div class="pr-[40px] text-sm font-semibold">{{ title }}</div>
    </div>
    <p v-if="description" class="mb-2 px-2 mt-2 text-sm font-normal">
      {{ description }}
    </p>
    <div
      v-if="buttons?.length > 0"
      class="actions mt-2 flex flex-wrap items-center"
    >
      <RiseButton
        v-for="(button, i) in buttons"
        :key="i.toString() + button.text"
        :variant="variant"
        :text="button.text"
        @click="button.click"
      />
    </div>

    <div class="w-full bg-gray-200 rounded-b-full h-0.5 dark:bg-gray-700">
      <div
        :class="progressBgVariants[variant]"
        :style="{ width: `${progress}%` }"
      />
    </div>
  </div>
</template>
