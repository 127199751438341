import { defineStore } from "pinia";
import { getTasks } from "@/services/source";
import { getInvites } from "~/services/talentImport";

export const useTasksStore = defineStore("tasks", {
  state: () => ({
    items: [],
  }),
  getters: {
    hasInviteTask(state) {
      return state.items.some((task) => task?.type === "talent_import_invite");
    },
  },
  actions: {
    async fetch() {
      try {
        const clerkSessionStore = useClerkSessionStore();
        if (!clerkSessionStore.hasSession()) return;

        const tasks = await getTasks();
        if (!tasks) return;

        const filteredTasks = tasks.filter(
          (task) => task?.type !== "talent_import_invite"
        );
        const invites = await getInvites();
        if (!invites) return;

        invites.forEach((invite) => {
          if (!invite.converted)
            filteredTasks.push({
              name: `${invite.company.name} invited you!`,
              type: "invite",
              icon: "user-plus",
              ...invite,
            });
        });

        this.items = filteredTasks;
      } catch (e) {
        console.error("Failed to fetch invite", e);
        localStorage?.removeItem("talent_import");
      }
    },
  },
});
