<script setup>
const props = defineProps({
  error: Object,
});
const router = useRouter();
const toast = inject("toast");
const sentry = inject("sentry");

if (sentry) {
  sentry.captureException(props.error);
}

toast?.error(props.error.message);
router.push("/");
</script>

<template>
  <div class="pt-10 text-center">
    <h2 class="mb-2 text-2xl font-medium">{{ error.message }}</h2>
    <p class="text-gray-500">Redirecting to homepage...</p>
  </div>
</template>
