import { defineStore } from "pinia";
import { useSessionStore } from "./session";
import blockchainService from "~/services/blockchain";
import delegatesService from "~/services/delegates";

export const useRiseIdStore = defineStore("riseId", {
  state: () => ({
    address: null,
    owner: null,
    ramps: null,
    role: null,
    wallets: null,
    delegates: null,
  }),

  getters: {
    isOwnerARSK(state) {
      const sessionStore = useSessionStore();
      return sessionStore.wallets?.some(
        (wallet) => wallet.address.toLowerCase() === state.owner?.toLowerCase()
      );
    },
  },

  actions: {
    populateData(data) {
      this.address = data.address;
      this.owner = data.owner;
      this.ramps = data.ramps;
      this.role = data.role;
      this.wallets = data.wallets;
      this.delegates = data.delegates;
    },

    CLEAR_STATE() {
      this.address = null;
      this.owner = null;
      this.ramps = null;
      this.role = null;
      this.wallets = null;
      this.delegates = null;
    },

    async getInfo() {
      const sessionStore = useSessionStore();
      const result = await blockchainService.getRiseIdData(sessionStore.riseId);
      this.populateData(result.data);
    },

    async getDelegates() {
      const sessionStore = useSessionStore();
      this.delegates = [];
      if (sessionStore.riseId === "pending" || !sessionStore.riseId) return;
      try {
        const result = await delegatesService.getDelegates();
        this.delegates = result;
        return result;
      } catch (e) {
        console.error(e);
        if (!this.delegates?.length) this.delegates = null;
      }
    },

    async getOwner(riseId) {
      this.owner = "Loading";
      try {
        if (riseId === "pending") {
          return "Pending";
        } else if (riseId) {
          const result = (await blockchainService.getDynamic(`riseid/owner`))
            .data;
          if (!result) return;
          this.owner = result;
          return result;
        }
        return null;
      } catch (e) {
        console.error(e);
        if (this.owner === "Loading") this.owner = null;
      }
    },
  },
});
