import Clerk from "@clerk/clerk-js";

export default defineNuxtPlugin(async (nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const app = nuxtApp.vueApp;
  let clerk = null;
  const clerkDevDevPublishableKey =
    "pk_test_c2FmZS1zaGVwaGVyZC04Ni5jbGVyay5hY2NvdW50cy5kZXYk";
  const publishableKey = runtimeConfig.public.isLocalDevelopment
    ? clerkDevDevPublishableKey
    : runtimeConfig.public.CLERK_V1_PUBLISHABLE_KEY;

  clerk = new Clerk(publishableKey);
  app.provide("clerk", clerk);
  app.config.globalProperties.$clerk = clerk;
  await clerk?.load();
});
