import { ethers } from "ethers";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
import { api } from "@/utils/api";
import { pick } from "@/utils/helpers";

let wallet = null;

export function getWallet() {
  return api.get("/wallet-addresses");
}

export async function decryptWallet(password, recaptchaToken) {
  const walletEntity = await getWallet();
  if (!walletEntity.length) throw new Error("Could not find any wallet");
  const data = (await getWalletData(walletEntity[0].address), recaptchaToken)
    .data;
  const decryptedWallet = AES.decrypt(data, password);
  let walletJSON;
  try {
    walletJSON = decryptedWallet.toString(CryptoJS.enc.Utf8);
  } catch (e) {
    console.log("Decrypted wallet throw", e);
    throw new Error("Invalid Security Key password");
  }
  wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, password);
  return wallet;
}

export function storeWallet(data) {
  wallet = data;
}

export function getConnectedWallet() {
  return wallet;
}

export async function createEncryptedWallet(password, recaptchaToken) {
  const wallet = await ethers.Wallet.createRandom();
  const walletJSON = await wallet.encrypt(password);
  const data = AES.encrypt(walletJSON, password).toString();
  await saveWallet({ address: wallet.address, data }, recaptchaToken);
  return wallet;
}

export async function recoverEncryptedWallet(
  mnemonic,
  password,
  recaptchaToken
) {
  const wallet = await ethers.Wallet.fromMnemonic(mnemonic);
  const walletJSON = await wallet.encrypt(password);
  const data = AES.encrypt(walletJSON, password).toString();
  await api.put(`/wallet-addresses/recover?recaptchaToken=${recaptchaToken}`, {
    address: wallet.address,
    data,
  });
  return wallet;
}

export async function recoverSecurityKey(mnemonic, password, recaptchaToken) {
  const wallet = await ethers.Wallet.fromMnemonic(mnemonic);
  const walletJSON = await wallet.encrypt(password);
  const data = AES.encrypt(walletJSON, password).toString();
  await api.put(`/wallet-addresses/recover?recaptchaToken=${recaptchaToken}`, {
    address: wallet.address,
    data,
  });

  return wallet;
}

export async function importWallet(mnemonic, password, recaptchaToken) {
  const wallet = await ethers.Wallet.fromMnemonic(mnemonic);
  const walletJSON = await wallet.encrypt(password);
  const data = AES.encrypt(walletJSON, password).toString();
  await saveWallet({ address: wallet.address, data }, recaptchaToken);
  return wallet;
}

export function saveWallet(params, recaptchaToken) {
  const whitelist = "address,data";
  return api.post(
    `/wallet-addresses?recaptchaToken=${recaptchaToken}`,
    pick(params, whitelist)
  );
}

export function getWalletData(address, recaptchaToken) {
  return api.get(
    `/wallet-addresses/${address}/data?recaptchaToken=${recaptchaToken}`
  );
}

export function signMessage(message) {
  return new Promise((resolve, reject) => {
    const rsk = document.getElementById("rsk-iframe").contentWindow;
    const listener = window.addEventListener("message", (event) => {
      if (event.data.type === "signMessage") {
        window.removeEventListener("message", listener);
        resolve(event.data.payload);
      } else if (event.data.type === "signMessage_error") {
        window.removeEventListener("message", listener);
        reject(event.data.payload);
      }
    });
    rsk.postMessage(
      {
        type: "signMessage",
        payload: message,
      },
      "*"
    );
  });
}

export function signTransaction(transaction) {
  return new Promise((resolve, reject) => {
    const rsk = document.getElementById("rsk-iframe").contentWindow;
    const listener = window.addEventListener("message", (event) => {
      if (event.data.type === "signTransaction") {
        window.removeEventListener("message", listener);
        resolve(event.data.payload);
      } else if (event.data.type === "signTransaction_error") {
        window.removeEventListener("message", listener);
        reject(event.data.payload);
      }
    });
    rsk.postMessage(
      {
        type: "signMessage",
        payload: transaction,
      },
      "*"
    );
  });
}

export function _signTypedData(payload) {
  return new Promise((resolve, reject) => {
    const rsk = document.getElementById("rsk-iframe").contentWindow;
    const listener = window.addEventListener("message", (event) => {
      if (event.data.type === "_signTypedData") {
        window.removeEventListener("message", listener);
        resolve(event.data.payload);
      } else if (event.data.type === "_signTypedData_error") {
        window.removeEventListener("message", listener);
        reject(event.data.payload);
      }
    });
    rsk.postMessage(
      {
        type: "_signTypedData",
        payload,
      },
      "*"
    );
  });
}

export default {
  decryptWallet,
  signMessage,
  signTransaction,
  _signTypedData,
  recoverSecurityKey,
};
