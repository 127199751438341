import { ethers } from "ethers";
import { api, http } from "@/utils/api";
import { objectToQueryStr, pick } from "@/utils/helpers";
import validators from "@/utils/validators";

export function getBlockchainNetwork(chainId = null) {
  const runtimeConfig = useRuntimeConfig();
  const API_URL = runtimeConfig.public.API_URL;
  return http.get(
    `${API_URL}/blockchain/contracts/network${
      chainId ? `?chainId=${chainId}` : ""
    }`
  );
}

export function getContractByName(name) {
  return api.get(`/blockchain/contracts/name/${name}`);
}

export function getTokens() {
  return api.get("/blockchain/tokens");
}

export function getACHLimits() {
  return api.get("/achLimits");
}

export function pay(params) {
  const whitelist = "request,signature";
  return api.post("/b2b/pay", pick(params, whitelist));
}

export function addPayee(params, query) {
  const whitelist = "payeeId";
  const _query = objectToQueryStr(query);
  return api.post(
    `/blockchain/riseid/add-payee?${_query}`,
    pick(params, whitelist)
  );
}

export function getDynamic(route, query) {
  const _query = objectToQueryStr(query);
  return api.get(`/blockchain/${route}?${_query}`);
}

export function getDynamicPublic(route) {
  return http.get(`${API_URL}/blockchain/${route}`);
}

export function putDynamicPublic(route) {
  return http.put(`${API_URL}/blockchain/${route}`);
}

export function putDynamic(route, params, query) {
  const _query = objectToQueryStr(query);
  return api.put(`/blockchain/${route}?${_query}`, params);
}

export function postDynamicPublic(route, params) {
  const runtimeConfig = useRuntimeConfig();
  const API_URL = runtimeConfig.public.API_URL;
  return http.post(`${API_URL}/${route}`, params);
}

export function postDynamic(route, params, query) {
  const _query = objectToQueryStr(query);
  const whitelist =
    "id,clientId,request,signature,ids,schedule,amount,amountUSD,ramp,type,owner,rise_id,dest,name,address,paySchedule,payIntents,payeeServiceDescription,payeeTitle,paySalt,currency,wallet,accountId,payee,vendor,disableSOW,companySOWUrl";
  return api.post(`/blockchain/${route}?${_query}`, pick(params, whitelist));
}

export function fundWithCircleACH(accountId, params) {
  const whitelist = "amountUSD,signature,message,wallet";
  return api.post(
    `/blockchain/fund/ach?accountId=${accountId}`,
    pick(params, whitelist)
  );
}

export function getFundWithCircleACH(accountId, amountUSD) {
  return api.get(
    `/blockchain/fund/ach?accountId=${accountId}&amountUSD=${amountUSD}`
  );
}

export function createRiseID({
  address,
  companyId,
  signature,
  recaptchaToken,
}) {
  return api.post(`/blockchain/riseid?recaptchaToken=${recaptchaToken}`, {
    address,
    companyId,
    signature,
  });
}

export function tokenBalanceOf(params) {
  return api.get(
    `/blockchain/tokens/${params.token}/balance-of/${params.account}?network=${
      params.network || "arbitrum"
    }`
  );
}

export function risePayBalanceOf(address) {
  if (!validators.isAddress(address)) return { data: "0" };
  return api.get(`/blockchain/riseid/balance`);
}

export function transferTokens() {
  return api.get(`/tokens/transfer?network=arbitrum`);
}

export function USDCbalanceOf(address) {
  const runtimeConfig = useRuntimeConfig();
  return api.get(
    `/blockchain/tokens/${runtimeConfig.public.USDC}/balance-of/${address}`
  );
}

export async function EthBalanceOf(address) {
  return await api.get(`/blockchain/rpc/balance-of?account=${address}`);
}

export function USDCAllowanceOf(params) {
  const query = {
    spenders: params.spender ? [params.spender] : params.spenders,
  };
  const runtimeConfig = useRuntimeConfig();
  return getDynamic(
    `tokens/${runtimeConfig.public.USDC}/allowance/${params.owner}`,
    query
  );
}

export function allowanceOf(params) {
  const query = {
    spenders: params.spender ? [params.spender] : params.spenders,
  };
  return getDynamic(`tokens/${params.token}/allowance/${params.owner}`, query);
}

export function getRiseIdData(riseId, query = {}) {
  return getDynamic(`riseid`, query);
}

export function getSubscriptions() {
  return getDynamic("subscriptions");
}

export function getHistory(address) {
  const provider = new ethers.providers.EtherscanProvider("sepolia");
  return provider.getHistory(address);
}

export function putTransferOwnership(riseId, newOwner, wallet) {
  return api.put(`/blockchain/riseid/transfer-ownership`, {
    newOwnerAddress: newOwner,
    wallet,
  });
}

export function postTransferOwnership(riseId, body) {
  return api.post(`/blockchain/riseid/transfer-ownership`, body);
}

export function mintUSDC(wallet) {
  return api.post(`/usdc/mint/${wallet}`);
}

export function putwithdrawTokenRiseID(to, tokenAddress, body) {
  return api.put(`/blockchain/arbitrum/${tokenAddress}/transfer/${to}`, body);
}

export function postwithdrawTokenRiseID(to, tokenAddress, body) {
  return api.post(`/blockchain/arbitrum/${tokenAddress}/transfer/${to}`, body);
}

export default {
  putTransferOwnership,
  postTransferOwnership,
  getACHLimits,
  EthBalanceOf,
  getBlockchainNetwork,
  getDynamic,
  allowanceOf,
  USDCAllowanceOf,
  getDynamicPublic,
  postDynamicPublic,
  postDynamic,
  putDynamic,
  putDynamicPublic,
  getContractByName,
  USDCbalanceOf,
  fundWithCircleACH,
  getFundWithCircleACH,
  getRiseIdData,
  createRiseID,
  tokenBalanceOf,
  getHistory,
  getTokens,
  risePayBalanceOf,
  getSubscriptions,
  pay,
  addPayee,
  mintUSDC,
};
