import { default as accountkG38BDP4FJMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue?macro=true";
import { default as indexDTwcKAhpQlMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue?macro=true";
import { default as blockchain_45walletDl3xpMfuuzMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue?macro=true";
import { default as dedicated_45address2uHGU9ftREMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue?macro=true";
import { default as optionsI7f2rpbdzGMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue?macro=true";
import { default as indexF93k4zajQyMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue?macro=true";
import { default as binancektc4KBkxVNMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue?macro=true";
import { default as _91network_93byvZOYnHUpMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue?macro=true";
import { default as index6HCjIFhkynMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue?macro=true";
import { default as brlaJjB1TULijgMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue?macro=true";
import { default as coinbase_walletmAYa2mKPJCMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue?macro=true";
import { default as coinbaseA2Kk1Zup2mMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue?macro=true";
import { default as euraCvQsBYS1cMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue?macro=true";
import { default as forexMklgwJyzvyMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue?macro=true";
import { default as gbpzwVi0KeaO2Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue?macro=true";
import { default as international_bankX7whsWyNKRMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue?macro=true";
import { default as internationalHwymXruIjDMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue?macro=true";
import { default as kraken5QIHuvB4O9Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue?macro=true";
import { default as ngnR9FnAver7pMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue?macro=true";
import { default as usfReUzTFNnCMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue?macro=true";
import { default as options8rH5RNH2BSMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue?macro=true";
import { default as rampslCSIqCopzDMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue?macro=true";
import { default as coinbaseQzv7uMHqOfMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue?macro=true";
import { default as verify_45walletUQmwN3sRnJMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue?macro=true";
import { default as _91uuid_93Ci9cOIt6yeMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93FcORiL3izMMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as loginkucTUglM8MMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue?macro=true";
import { default as registerf9HCc2VfS3Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue?macro=true";
import { default as blockedmoNoI5X1ixMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue?macro=true";
import { default as classification0uvss28gw4Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue?macro=true";
import { default as indexnwfk5tDMAdMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue?macro=true";
import { default as indexX14YBNJlgRMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue?macro=true";
import { default as dashboard3lUkuULwmpMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue?macro=true";
import { default as developerB5naVduaJTMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue?macro=true";
import { default as pay_schedulehSs1WPWPI6Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue?macro=true";
import { default as index1jlyZIHv5tMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue?macro=true";
import { default as _91accountId_93Zqmyqzl9qmMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue?macro=true";
import { default as deposit_45usybXuMiyKQ8Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue?macro=true";
import { default as depositnk1MJ7voZTMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue?macro=true";
import { default as _91accountId_93zGWSH0Sq7YMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue?macro=true";
import { default as riseidBzShWxREJKMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue?macro=true";
import { default as fund2sOGJwQdfcMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue?macro=true";
import { default as index2pOshChAArMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue?macro=true";
import { default as _91code_93Y5qlOl6fZTMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue?macro=true";
import { default as _91id_93yxwENc9ap6Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue?macro=true";
import { default as indexgmWMXG7SN0Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue?macro=true";
import { default as indexeGdrQYSXINMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue?macro=true";
import { default as jwtSrOKb1CotkMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue?macro=true";
import { default as indexNWQplTS8kXMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue?macro=true";
import { default as teamsARv8yrkdYMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue?macro=true";
import { default as indextJf38Vkms8Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue?macro=true";
import { default as onboardingWeZmjqaC5nMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue?macro=true";
import { default as hourlyMDTOMNxjzmMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue?macro=true";
import { default as instantTQktiS65hqMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue?macro=true";
import { default as milestone9OwlPQrI1jMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue?macro=true";
import { default as onceRA8BqEf4PGMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue?macro=true";
import { default as recurringDLaa6ZGWERMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue?macro=true";
import { default as _91userId_93LuOPCQ6I61Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue?macro=true";
import { default as csvlk5yQrcGy3Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue?macro=true";
import { default as indexWa5lMx3t30Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue?macro=true";
import { default as index8OTDXeq5fDMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue?macro=true";
import { default as management7ELIcPd1QRMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue?macro=true";
import { default as _91id_93pthlmnFUiCMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue?macro=true";
import { default as _91id_93JKZc0kQPSeMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue?macro=true";
import { default as _91psId_9373gTzg1vFfMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue?macro=true";
import { default as indexcHtHBnBby6Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue?macro=true";
import { default as transaction_45historyRkL2gjcLD8Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue?macro=true";
import { default as playgroundNA6KTKcSdpMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue?macro=true";
import { default as businessIgzUKoebVqMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue?macro=true";
import { default as indexV40eGVu24KMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue?macro=true";
import { default as referralvSJKjy6MkWMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue?macro=true";
import { default as riseid_45erc20_45withdrawzvAPcmkzBPMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue?macro=true";
import { default as riseidNRwlpMAeESMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue?macro=true";
import { default as securitySnQy60tJPWMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue?macro=true";
import { default as sign_45documenttsJFk7trmWMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue?macro=true";
import { default as _2fa0r1eGgFYDFMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue?macro=true";
import { default as callbackuTi9Poby8pMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue?macro=true";
import { default as codeTfCuyjiMEsMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue?macro=true";
import { default as index6BvZltAms6Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue?macro=true";
import { default as sign_45outx9Cp7kf7anMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue?macro=true";
import { default as code1OwyH2PVkzMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue?macro=true";
import { default as indexXcvYFvSoa2Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue?macro=true";
import { default as waiting9fCgXblDzVMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue?macro=true";
import { default as indexQFFROlxwwtMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue?macro=true";
import { default as optionsYarPHX5tfAMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue?macro=true";
import { default as indexLNTq5El7xKMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue?macro=true";
import { default as importVSsK77fOkZMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue?macro=true";
import { default as indexVppXPGnXKqMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue?macro=true";
import { default as batch_warmed_lightwYok3vMgZQMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue?macro=true";
import { default as batch_warmedloe2sEnds8Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue?macro=true";
import { default as directph6A6NFME7Meta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue?macro=true";
import { default as sumsub4AAOGmQ70pMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue?macro=true";
import { default as warmed_lightuJ4y5Q0yZwMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue?macro=true";
import { default as warmedYv0LUN6pAnMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue?macro=true";
import { default as inviteY4W3VXigaQMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue?macro=true";
import { default as indexsChJqwdm6TMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue?macro=true";
import { default as _91accountId_93gZpqNhFaXVMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue?macro=true";
import { default as _91accountId_93nk08CPPscSMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue?macro=true";
import { default as withdrawgvR5rrkl3lMeta } from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountkG38BDP4FJMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding",
    path: "/accounts/add-funding",
    meta: indexDTwcKAhpQlMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options",
    path: "/accounts/add-funding/options",
    meta: optionsI7f2rpbdzGMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-funding-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options-dedicated-address",
    path: "dedicated-address",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw",
    path: "/accounts/add-withdraw",
    meta: indexF93k4zajQyMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options",
    path: "/accounts/add-withdraw/options",
    meta: options8rH5RNH2BSMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-withdraw-options-binance",
    path: "binance",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet-network",
    path: "blockchain-wallet/:network()",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-brla",
    path: "brla",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase_wallet",
    path: "coinbase_wallet",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-eur",
    path: "eur",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-forex",
    path: "forex",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-gbp",
    path: "gbp",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international_bank",
    path: "international_bank",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international",
    path: "international",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-kraken",
    path: "kraken",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-ngn",
    path: "ngn",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-us",
    path: "us",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw-ramps",
    path: "/accounts/add-withdraw/ramps",
    meta: rampslCSIqCopzDMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-coinbase",
    path: "/accounts/add/callback/coinbase",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-verify-wallet",
    path: "/accounts/add/callback/verify-wallet",
    meta: verify_45walletUQmwN3sRnJMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "blocked",
    path: "/blocked",
    meta: blockedmoNoI5X1ixMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-classification",
    path: "/clients/:id()/classification",
    meta: classification0uvss28gw4Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue").then(m => m.default || m)
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: indexnwfk5tDMAdMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexX14YBNJlgRMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard3lUkuULwmpMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "developer",
    path: "/developer",
    meta: developerB5naVduaJTMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: "documents-payschedule-pay_schedule",
    path: "/documents/:payschedule()/pay_schedule",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: index1jlyZIHv5tMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "fund",
    path: "/fund",
    meta: fund2sOGJwQdfcMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-dedicated-address-accountId",
    path: "dedicated-address/:accountId()",
    meta: _91accountId_93Zqmyqzl9qmMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit-us",
    path: "deposit-us",
    meta: deposit_45usybXuMiyKQ8Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit",
    path: "deposit",
    meta: depositnk1MJ7voZTMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue").then(m => m.default || m)
  },
  {
    name: "fund-external-account-accountId",
    path: "external-account/:accountId()",
    meta: _91accountId_93zGWSH0Sq7YMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-riseid",
    path: "riseid",
    meta: riseidBzShWxREJKMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: index2pOshChAArMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add-id",
    path: "/invoices/add/:id()",
    meta: _91id_93yxwENc9ap6Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add",
    path: "/invoices/add",
    meta: indexgmWMXG7SN0Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: indexeGdrQYSXINMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    meta: jwtSrOKb1CotkMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create",
    path: "/management/company/create",
    meta: indexNWQplTS8kXMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create-team",
    path: "/management/company/create/team",
    meta: teamsARv8yrkdYMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue").then(m => m.default || m)
  },
  {
    name: "management-company",
    path: "/management/company",
    meta: indextJf38Vkms8Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingWeZmjqaC5nMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId",
    path: "/payments/:userId()",
    meta: _91userId_93LuOPCQ6I61Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "payments-userId-hourly",
    path: "hourly",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-instant",
    path: "instant",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-milestone",
    path: "milestone",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-once",
    path: "once",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-recurring",
    path: "recurring",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-batch-csv",
    path: "/payments/batch/csv",
    meta: csvlk5yQrcGy3Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "payments-batch",
    path: "/payments/batch",
    meta: indexWa5lMx3t30Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    meta: index8OTDXeq5fDMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management",
    path: "/payments/management",
    meta: management7ELIcPd1QRMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-fund-id",
    path: "/payments/receipts/fund/:id()",
    meta: _91id_93pthlmnFUiCMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-payment-id",
    path: "/payments/receipts/payment/:id()",
    meta: _91id_93JKZc0kQPSeMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets-psId",
    path: "/payments/timesheets/:psId()",
    meta: _91psId_9373gTzg1vFfMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets",
    path: "/payments/timesheets",
    meta: indexcHtHBnBby6Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-transaction-history",
    path: "/payments/transaction-history",
    meta: transaction_45historyRkL2gjcLD8Meta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "profile-business",
    path: "/profile/business",
    meta: businessIgzUKoebVqMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexV40eGVu24KMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: referralvSJKjy6MkWMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "riseid-erc20-withdraw",
    path: "/riseid-erc20-withdraw",
    meta: riseid_45erc20_45withdrawzvAPcmkzBPMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue").then(m => m.default || m)
  },
  {
    name: "riseid",
    path: "/riseid",
    meta: riseidNRwlpMAeESMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue").then(m => m.default || m)
  },
  {
    name: "security",
    path: "/security",
    meta: securitySnQy60tJPWMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "sign-document",
    path: "/sign-document",
    meta: sign_45documenttsJFk7trmWMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-callback",
    path: "/sign-in/callback",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outx9Cp7kf7anMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waiting9fCgXblDzVMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: indexQFFROlxwwtMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-options",
    path: "/subscription/options",
    meta: optionsYarPHX5tfAMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue").then(m => m.default || m)
  },
  {
    name: "talent-id",
    path: "/talent/:id()",
    meta: indexLNTq5El7xKMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-import",
    path: "/talent/import",
    meta: importVSsK77fOkZMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue").then(m => m.default || m)
  },
  {
    name: "talent",
    path: "/talent",
    meta: indexVppXPGnXKqMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite",
    path: "/talent/invite",
    meta: inviteY4W3VXigaQMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue").then(m => m.default || m),
    children: [
  {
    name: "talent-invite-batch_warmed_light",
    path: "batch_warmed_light",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-batch_warmed",
    path: "batch_warmed",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-direct",
    path: "direct",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-sumsub",
    path: "sumsub",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed_light",
    path: "warmed_light",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed",
    path: "warmed",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexsChJqwdm6TMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "withdraw",
    path: "/withdraw",
    meta: withdrawgvR5rrkl3lMeta || {},
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "withdraw-blockchain-accountId",
    path: "blockchain/:accountId()",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "withdraw-wire-accountId",
    path: "wire/:accountId()",
    component: () => import("/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue").then(m => m.default || m)
  }
]
  }
]