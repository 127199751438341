import jwtDecode from "jwt-decode";

const AUTH_NEXT_KEY = "riseAuthRedirect";
const AUTH_TOKEN_KEY = "riseAuthToken";

export async function loginViaAccessToken(provider) {
  const runtimeConfig = useRuntimeConfig();
  if (!window.location.search.includes("access_token"))
    throw "Missing Access Token";
  let queryParams = window.location.search;
  const sid = sessionStorage.getItem("rise_social_id");
  if (sid) queryParams += `&sid=${encodeURIComponent(sid)}`;
  const res = await getViaWebWorker(
    `${runtimeConfig.public.API_URL}/auth/${provider}/callback/${queryParams}`
  );

  setAuth(res.jwt);
  return isAuthenticated();
}

async function getViaWebWorker(url) {
  return new Promise((resolve, reject) => {
    const worker = new Worker("/worker.js");
    worker.postMessage(url);
    worker.onmessage = (e) => {
      resolve(JSON.parse(e.data));
    };
    worker.onerror = (e) => {
      reject(e);
    };
  });
}

export function isAuthenticated() {
  const jwt = window.localStorage.getItem(AUTH_TOKEN_KEY);
  if (!jwt) return false;
  const jwtDecoded = jwtDecode(jwt);
  return !isExpired(jwtDecoded.exp);
}

export function getAuth() {
  try {
    const jwt = window.localStorage.getItem(AUTH_TOKEN_KEY);
    if (!jwt) return;
    const jwtDecoded = jwtDecode(jwt);
    if (isExpired(jwtDecoded.exp)) return;
    return jwt;
  } catch (e) {
    logout();
    throw new Error(e);
  }
}

export function setAuth(jwt) {
  // logout()
  // if (!jwt || !validators.isBase64(jwt)) throw new Error('Invalid JWT') // TODO
  if (!jwt) throw new Error("Invalid JWT");
  window.localStorage.setItem(
    AUTH_TOKEN_KEY,
    `Bearer ${jwt.toString().trim()}`
  );
}

export function logout() {
  window.localStorage.removeItem(AUTH_TOKEN_KEY);
  window.sessionStorage.removeItem(AUTH_NEXT_KEY);
  window.localStorage.removeItem("vuex"); // !important clears vuex-persistent storage
}

export function getAndClearNext() {
  const nextPath = window.sessionStorage.getItem(AUTH_NEXT_KEY);
  window.sessionStorage.removeItem(AUTH_NEXT_KEY);
  return nextPath;
}

export function setAuthNext(path) {
  window.sessionStorage.setItem(AUTH_NEXT_KEY, path);
}

function isExpired(exp) {
  if (!exp) return true;
  const now = Math.floor(Date.now() / 1000);
  return now > exp;
}
