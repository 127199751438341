import { api, http } from "@/utils/api";

export async function getCountries() {
  const runtimeConfig = useRuntimeConfig();
  const API_URL = runtimeConfig.public.API_URL;
  const countries = await http.get(`${API_URL}/countries`);
  return countries.map((country) => {
    return { value: country.id, label: country.name };
  });
}

export function getTimezones(params) {
  return api.get(`/timezones/${params.countryCode}`);
}

export function getTasks() {
  return api.get("/tasks");
}

export default {
  getCountries,
  getTimezones,
  getTasks,
};
