// import * as Sentry from '@nuxtjs/sentry';
import { ofetch } from "ofetch";
import { useNuxtApp } from "#app";

async function apiFetch(path, params = {}, auth = true) {
  const nuxtApp = useNuxtApp();
  const app = nuxtApp.vueApp;

  const session = app.config.globalProperties.$clerk?.session;

  if (auth && !session) {
    console.log("No auth token. Logging out.");
    nuxtApp.$router.push("/sign-out");
    return;
  }

  let jwt = "";

  if (auth) {
    const token = await session.getToken({
      template: "User_Template",
    });

    jwt = `Bearer ${token}`;
  }

  params = {
    ...params,
    headers: {
      ...params.headers,
      Authorization: jwt || "",
      Accept: "application/json",
    },
    async onRequestError({ error }) {
      app.config.globalProperties.$toastError(error?.toString());
    },
    async onResponseError({ request, response }) {
      if (request.url?.includes("affiliate")) {
        return;
      }
      if (
        response.status === 401 &&
        response._data?.message?.includes("Invalid token")
      ) {
        console.log("Token expired. Logging out.");
        nuxtApp.$router.push("/sign-out");
        return;
      }
      const multipleErrors = response._data.message?.errors || null;

      if (multipleErrors) {
        const errorsArray = Object.keys(multipleErrors).map((e) => {
          return multipleErrors[e][0];
        });
        app.config.globalProperties.$toastError(errorsArray[0]);
        return;
      }

      if (typeof response._data === "string") {
        app.config.globalProperties.$toastError(response._data);
        return;
      }

      app.config.globalProperties.$toastError(
        response._data?.message?.toString()
      );
    },
  };

  return ofetch(path, params);
}

// Rise API REST Method Helpers
export const api = {
  delete: async (path, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "DELETE",
    });
  },
  get: async (path, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, params);
  },
  patch: async (path, data, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "PATCH",
      body: JSON.stringify(data),
    });
  },
  patchFormData: async (path, data, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "PATCH",
      body: JSON.stringify(data),
    });
  },
  post: async (path, data, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "POST",
      body: JSON.stringify(data),
    });
  },
  postFormData: async (path, data, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "POST",
      body: data,
    });
  },
  put: async (path, data, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "PUT",
      body: JSON.stringify(data),
    });
  },
  putFormData: async (path, data, params = {}) => {
    const runtimeConfig = useRuntimeConfig();
    return await apiFetch(`${runtimeConfig.public.API_URL}${path}`, {
      ...params,
      method: "PUT",
      body: data,
    });
  },
};

// External HTTP Calls
export const http = {
  delete: async (path, params = {}) => {
    return await apiFetch(path, { ...params, method: "DELETE" }, false);
  },
  get: async (path, params = {}) => {
    return await apiFetch(
      path,
      {
        ...params,
        method: "GET",
      },
      false
    );
  },
  patch: async (path, data, params = {}) => {
    return await apiFetch(
      path,
      {
        ...params,
        method: "PATCH",
        body: JSON.stringify(data),
      },
      false
    );
  },
  post: async (path, data, params = {}) => {
    return await apiFetch(
      path,
      {
        ...params,
        method: "POST",
        body: JSON.stringify(data),
      },
      false
    );
  },
  postFormData: async (path, data, params = {}) => {
    return await apiFetch(
      path,
      {
        ...params,
        method: "POST",
        body: data,
      },
      false
    );
  },
  put: async (path, data, params = {}) => {
    return await apiFetch(
      path,
      {
        ...params,
        method: "PUT",
        body: JSON.stringify(data),
      },
      false
    );
  },
};
