import { ofetch } from "ofetch";
import { getAuth } from "@/utils/authStorage";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const app = nuxtApp.vueApp;
  app.config.globalProperties.$fetch = ofetch.create({
    baseUrl: config.public.API_URL,
    onRequest: (req) => {
      req.headers.set("Authorization", getAuth());
      req.headers.set("Accept", "application/json");
    },
  });
});
