import { api, http } from "@/utils/api";

export function getTalentImportByUuid(uuid) {
  const runtimeConfig = useRuntimeConfig();
  const API_URL = runtimeConfig.public.API_URL;
  return http.get(`${API_URL}/talent-imports/${uuid}`);
}

export function getTalentImports(params) {
  return api.get(`/talent-imports?${new URLSearchParams(params).toString()}`);
}

export function getInvites() {
  return api.get("/talent-imports/forme");
}

export function putTalentImport(params) {
  return api.put(
    `/talent-imports/${params.uuid}?recaptchaToken=${params.recaptchaToken}`
  );
}

export function warmedInvite(payload, recaptchaToken) {
  return api.post(
    `/talent-imports/warmed?recaptchaToken=${recaptchaToken}`,
    payload
  );
}

export function warmedInviteLight(payload, recaptchaToken) {
  return api.post(
    `/talent-imports/warmed/light?recaptchaToken=${recaptchaToken}`,
    payload
  );
}

export function declineTalentImport(params) {
  return api.put(
    `/talent-imports/decline/${params.uuid}?recaptchaToken=${params.recaptchaToken}`
  );
}

export function deleteTalentImport(uuidOrId, recaptchaToken) {
  return api.delete(
    `/talent-imports/${uuidOrId}?recaptchaToken=${recaptchaToken}`
  );
}

export function remindTalent(uuid, recaptchaToken) {
  return api.post(
    `/talent-imports/${uuid}/remind?recaptchaToken=${recaptchaToken}`
  );
}

export function inviteMember(email, recaptchaToken) {
  return api.post(`/talent-imports?recaptchaToken=${recaptchaToken}`, {
    emailList: [email],
    role: "client",
  });
}

export function getInviteByToken(token) {
  const runtimeConfig = useRuntimeConfig();
  const API_URL = runtimeConfig.public.API_URL;
  return http.get(`${API_URL}/talent-imports/b2b-invite/${token}`);
}

export function acceptInvite(token, recaptchaToken) {
  return api.post(
    `/talent-imports/b2b-invite/${token}?recaptchaToken=${recaptchaToken}`
  );
}

export default {
  getTalentImportByUuid,
  inviteMember,
  getInvites,
  getTalentImports,
  getInviteByToken,
  acceptInvite,
};
