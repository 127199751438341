import { defineStore } from "pinia";

export const useLoaderStore = defineStore("loader", {
  state: () => ({
    requestsPending: 0,
    signData: null,
  }),

  getters: {
    isLoading(state) {
      return state.requestsPending > 0;
    },
    hasSignData(state) {
      return state.signData !== null;
    },
  },

  actions: {
    showSignData(signData) {
      this.signData = signData;
    },
    hideSignData() {
      this.signData = null;
    },
    show() {
      this.requestsPending++;
    },
    hide() {
      this.signData = null;
      this.requestsPending = Math.max(this.requestsPending - 1, 0);
    },
  },
});
