import revive_payload_client_lPBsiUxOX4 from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.10_eslint@8.57.1_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1oP6iU92Tq from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.10_eslint@8.57.1_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NelFWwDw0J from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.10_eslint@8.57.1_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_7oyXJ1ZIrh from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.10_eslint@8.57.1_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_d98AZ1c8vB from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.7.2_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/.nuxt/components.plugin.mjs";
import prefetch_client_Bu5ckhnSEb from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.10_eslint@8.57.1_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_QQzlTFXthU from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@vite-pwa+nuxt@0.5.0_rollup@2.79.2_vite@5.4.11_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_DCCjkWJyEc from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@types+node@20.17.10_eslint@8.57.1_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_lkc0gQn0X2 from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_pinia@2.3.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import clerk_client_sAvp4qAF8G from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/clerk.client.js";
import dateFns_client_zmk3aCrZdB from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/dateFns.client.js";
import filters_client_leW4wqvSMQ from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/filters.client.js";
import fontawesome_client_3AEyghtEUi from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/fontawesome.client.js";
import intercom_client_sjSaVpgP3i from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/intercom.client.js";
import loader_pK85tHLquW from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/loader.js";
import lottie_client_funiu1oBjt from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/lottie.client.js";
import mask_client_hSGYCqJ9ZQ from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/mask.client.js";
import ofetch_client_kdTHiAedZ8 from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/ofetch.client.js";
import pinia_client_MzxIWzYLDp from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/pinia.client.js";
import places_Op3OtRUgO6 from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/places.js";
import recaptcha_W4US4xmBV6 from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/recaptcha.js";
import rsk_7zLHSUX3HB from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/rsk.js";
import select_LicOUSSwxZ from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/select.js";
import sentry_client_GoGQuZo4Et from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/sentry.client.js";
import toast_client_437j7GlIrd from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/toast.client.js";
import tooltip_07WqZjDZUO from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/tooltip.js";
import vCalendar_client_K64EuUrEFD from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/vCalendar.client.js";
import web3modal_client_2yvFzQ6iu1 from "/data/runners/nine/_work/apps-monorepo/apps-monorepo/apps/pay/plugins/web3modal.client.js";
export default [
  revive_payload_client_lPBsiUxOX4,
  unhead_1oP6iU92Tq,
  router_NelFWwDw0J,
  check_outdated_build_client_7oyXJ1ZIrh,
  plugin_vue3_d98AZ1c8vB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Bu5ckhnSEb,
  pwa_client_QQzlTFXthU,
  chunk_reload_client_DCCjkWJyEc,
  plugin_lkc0gQn0X2,
  clerk_client_sAvp4qAF8G,
  dateFns_client_zmk3aCrZdB,
  filters_client_leW4wqvSMQ,
  fontawesome_client_3AEyghtEUi,
  intercom_client_sjSaVpgP3i,
  loader_pK85tHLquW,
  lottie_client_funiu1oBjt,
  mask_client_hSGYCqJ9ZQ,
  ofetch_client_kdTHiAedZ8,
  pinia_client_MzxIWzYLDp,
  places_Op3OtRUgO6,
  recaptcha_W4US4xmBV6,
  rsk_7zLHSUX3HB,
  select_LicOUSSwxZ,
  sentry_client_GoGQuZo4Et,
  toast_client_437j7GlIrd,
  tooltip_07WqZjDZUO,
  vCalendar_client_K64EuUrEFD,
  web3modal_client_2yvFzQ6iu1
]