import { api, http } from "@/utils/api";
import { pick } from "@/utils/helpers";

export function me() {
  return api.get("/user/me");
}

export function getUser(params) {
  return api.get(`/user/${params.id}`);
}

export function intercomHash() {
  return api.get("/user/hash");
}

export function onboard(params) {
  const whitelist =
    "agreement,firstname,middlename,lastname,email,confirmed,password,gravatar,avatar,phone,birthdate,website,address1,address2,city,state,country,zip,timezone,linkedin,hourlyRate,role,registrationType,businessName,businessTaxId,businessAddress1,businessAddress2,businessCity,businessState,businessCountry,businessZip,businessIncorporationState,businessWebsite,b2bInviteCode,address";
  return api.post("/user/onboard", pick(params, whitelist));
}

export function update(params, recaptchaToken) {
  const whitelist =
    "agreement,taxId,company,firstname,middlename,lastname,alias,email,recoveryEmail,confirmed,password,gravatar,avatar,phone,birthdate,website,address1,address2,city,state,country,zip,timezone,linkedin,hourlyRate,role,registrationType,businessName, occupation";
  return api.put(
    `/user?recaptchaToken=${recaptchaToken}`,
    pick(params, whitelist)
  );
}

export function changeEmail(token, newEmail, recaptchaToken) {
  return api.put(`/user/change-email?recaptchaToken=${recaptchaToken}`, {
    token,
    newEmail,
  });
}

export function setRole(roleName) {
  return api.put(`/user/${roleName}`);
}

export function updateAdvancedSettings(params) {
  const whitelist = "advancedSecurity";
  return api.put("/user/update-advanced-security", pick(params, whitelist));
}

export async function uploadAvatar(file, url) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "image/png",
    },
    body: file,
  });
}

export function setActiveCompany(id) {
  return api.put("/user/set-session-company", { companyId: id });
}

export function avatarSignedUrl() {
  return api.get("/user/avatar-upload");
}

export function updateAvatar(fileName) {
  return api.post("/user/update-avatar", { fileName });
}

export function sendWalletsSeen(wallets, recaptcha) {
  return api.post(`/user/wallet?recaptchaToken=${recaptcha}`, { wallets });
}

export function checkEmail(email, recaptchaToken) {
  const runtimeConfig = useRuntimeConfig();
  const API_URL = runtimeConfig.public.API_URL;
  return http.put(`${API_URL}/user/email?recaptchaToken=${recaptchaToken}`, {
    email,
  });
}

export function KYC() {
  return api.put("/user/kyc");
}

export function runKYC(captures, checks) {
  return api.post("/user/kyc", { captures, checks });
}

export function KYB(companyId) {
  return api.put(`/companies/${companyId}/kyb`);
}

export function runKYB(companyId, captures, checks) {
  return api.post(`/companies/${companyId}/kyb`, { captures, checks });
}

export default {
  me,
  getUser,
  uploadAvatar,
  avatarSignedUrl,
  setActiveCompany,
  updateAdvancedSettings,
  onboard,
  update,
  intercomHash,
  changeEmail,
  setRole,
  sendWalletsSeen,
};
