import { defineStore } from "pinia";
import { useSessionStore } from "./session";
import { useRiseIdStore } from "./riseId";
import { useLoaderStore } from "./loader";
import { useWalletNotAllowedStore } from "./walletNotAllowed";
import { sendWalletsSeen } from "~/services/user";

export const useWalletStore = defineStore("wallet", {
  state: () => ({
    walletAddress: null,
    walletType: null,
    walletLogo: null,
    chainId: null,
    isDelegatable: false,
    isActive: false,
  }),

  getters: {
    provider() {
      return window.provider;
    },
  },

  actions: {
    DISCONNECT() {
      const web3modal_iframe = document.getElementById("web3modal_iframe");
      if (web3modal_iframe) {
        web3modal_iframe.classList.remove("hidden");
        web3modal_iframe.contentWindow.postMessage({ type: "disconnect" }, "*");
      }
    },
    async connectWallet(check = true) {
      const clerkSessionStore = useClerkSessionStore();
      const jwt = await clerkSessionStore.jwt;
      const connectedWallet = await this.getAccount();
      if (connectedWallet) return;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const web3modal_iframe = document.getElementById("web3modal_iframe");
        web3modal_iframe.classList.remove("hidden");
        const sessionStore = useSessionStore();

        web3modal_iframe.contentWindow.postMessage(
          {
            type: "riseAuthToken",
            value: jwt,
          },
          "*"
        );

        let wallets = sessionStore.wallets;
        if (!wallets) {
          await sessionStore.getWallet();
          wallets = sessionStore.wallets;
        }
        let enabledSecKey = wallets.length > 0;

        const riseIdStore = useRiseIdStore();

        if (sessionStore.riseId) {
          if (!riseIdStore.wallets) {
            await riseIdStore.getInfo();
          }

          const connectedRskWalletAddress = sessionStore.wallets[0]?.address;
          const isWalletOwnerOrDelegate = this.isWalletOwnerOrDelegate({
            connectedWalletAddress: connectedRskWalletAddress,
          });
          if (!isWalletOwnerOrDelegate) {
            enabledSecKey = false;
          }
        }
        web3modal_iframe.contentWindow.postMessage(
          {
            type: "open_modal",
            payload: {
              jwt,
              rsk: enabledSecKey,
              advanced:
                sessionStore.advanced !== null ? sessionStore.advanced : true,
            },
          },
          "*"
        );
        const listener = async (event) => {
          const web3modal_iframe = document.getElementById("web3modal_iframe");
          if (event.data?.type === "modal_closed") {
            setTimeout(() => {
              web3modal_iframe.classList.add("hidden");
            }, 400);
            window.removeEventListener("message", listener);
            if (event.data.payload?.error) {
              reject(new Error(event.data.payload.error));
            } else if (event.data.payload) {
              this.walletAddress = event.data.payload.address;
              this.walletType = event.data.payload.type;
              const recaptcha = await this.$recaptcha("user_wallet_seen");
              sendWalletsSeen(
                [{ address: this.walletAddress, type: this.walletType }],
                recaptcha
              );
              this.isActive = true;
              if (check && riseIdStore.address) {
                const walletIsAuthorized = await this.checkIfWalletIsAuthorized(
                  {
                    connectedWalletAddress: this.walletAddress,
                  }
                );
                if (!walletIsAuthorized) {
                  reject(new Error("Wallet not authorized"));
                }
              }
            } else {
              this.walletAddress = null;
              this.walletType = null;
              this.isActive = false;
              reject(new Error("Not connected to a wallet."));
            }
            resolve();
          }
        };
        window.addEventListener("message", listener);
      });
    },

    async signTypedData(payload) {
      const clerkSessionStore = useClerkSessionStore();
      const jwt = await clerkSessionStore.jwt;
      return new Promise((resolve, reject) => {
        const loaderStore = useLoaderStore();
        const web3modal_iframe = document.getElementById("web3modal_iframe");

        if (payload.descriptor) loaderStore.showSignData(payload.descriptor);
        web3modal_iframe.contentWindow.postMessage(
          {
            type: "sign_typed_data",
            payload,
            jwt,
          },
          "*"
        );
        const listener = (event) => {
          if (event.data?.type === "sign_typed_data_response") {
            window.removeEventListener("message", listener);
            resolve(event.data.payload);
            loaderStore.hideSignData();
          }
          if (event.data?.type === "sign_typed_data_error") {
            window.removeEventListener("message", listener);
            reject(event.data.payload);
            loaderStore.hideSignData();
          }
        };
        window.addEventListener("message", listener);
      });
    },

    signMessage(payload) {
      return new Promise((resolve, reject) => {
        const web3modal_iframe = document.getElementById("web3modal_iframe");
        web3modal_iframe.contentWindow.postMessage(
          { type: "sign_message", payload },
          "*"
        );
        const listener = (event) => {
          if (event.data?.type === "sign_message_response") {
            window.removeEventListener("message", listener);
            resolve(event.data.payload);
          }
          if (event.data?.type === "sign_message_error") {
            window.removeEventListener("message", listener);
            reject(event.data.payload);
          }
        };
        window.addEventListener("message", listener);
      });
    },

    getAccount() {
      return new Promise((resolve, reject) => {
        const web3modal_iframe = document.getElementById("web3modal_iframe");
        const listener = (event) => {
          if (event.data?.type === "getAccount_response") {
            window.removeEventListener("message", listener);
            if (event.data.payload) {
              this.walletAddress = event.data.payload.address;
              this.walletType = event.data.payload.type;
              this.isActive = true;
            } else {
              this.walletAddress = null;
              this.walletType = null;
              this.isActive = false;
            }
            resolve(event.data.payload);
          }
        };
        window.addEventListener("message", listener);
        web3modal_iframe.contentWindow.postMessage({ type: "getAccount" }, "*");
      });
    },
    async checkIfWalletIsAuthorized({ connectedWalletAddress }) {
      const walletNotAllowedStore = useWalletNotAllowedStore();
      const riseIdStore = useRiseIdStore();

      await riseIdStore.getInfo();

      const isWalletOwnerOrDelegate = this.isWalletOwnerOrDelegate({
        connectedWalletAddress,
      });

      walletNotAllowedStore.isOpened = !isWalletOwnerOrDelegate;

      return isWalletOwnerOrDelegate;
    },
    isWalletOwnerOrDelegate({ connectedWalletAddress }) {
      const riseIdStore = useRiseIdStore();
      if (!riseIdStore.address) {
        return true;
      }
      const addresses = [riseIdStore.owner, ...riseIdStore.delegates];
      const availableWallets = addresses.map((wallet, i) => {
        let address;
        if (typeof wallet === "string") address = wallet;
        else address = wallet.wallet_address;
        const seen = riseIdStore.wallets.find(
          (wallet) => wallet.wallet_address === address
        );
        return {
          wallet_address: address,
          wallet_type: seen?.wallet_type || "Other",
        };
      });
      return availableWallets?.some(
        (e) =>
          e.wallet_address?.toLowerCase() ===
          connectedWalletAddress?.toLowerCase()
      );
    },
  },
});
