import BigNumber from "bignumber.js";
import { defineStore } from "pinia";
import User from "@/services/user";
import Company from "@/services/company";
import { getWallet } from "~/services/riseSecurityKey";
import { ROLES } from "@/utils/constants";
import blockchainService from "@/services/blockchain";
import validators from "@/utils/validators";

export const useSessionStore = defineStore("session", {
  state: () => ({
    user: null,
    wallets: null,
    hasWalletConnected: false,
    balance: null,
    activeCompany: null,
    timeout: null,
  }),
  getters: {
    rskWallet(state) {
      if (!state.wallets) return null;
      return state.wallets.find((e) => e.type === "rise");
    },
    company(state) {
      return state.user?.activeSessionCompany;
    },
    advanced(state) {
      return state.user?.advancedSecurity;
    },
    role(state) {
      return state.user?.role?.type;
    },
    country(state) {
      return state.user?.country;
    },
    funding(state) {
      return true;
    }, // state.user?.funding,
    withdraw(state) {
      return true;
    }, // state.user?.withdraw,
    isOnboarded(state) {
      return Boolean(
        state.user &&
          state.user?.role &&
          "contractor,client".includes(state.user?.role?.type)
      );
    },
    isContractor(state) {
      return Boolean(
        state.user &&
          state.user?.role &&
          state.user?.role?.type === ROLES.CONTRACTOR
      );
    },
    isClient(state) {
      return Boolean(
        state.user &&
          state.user?.role &&
          state.user?.role?.type === ROLES.CLIENT
      );
    },
    isLocalAccount(state) {
      return Boolean(state.user && state.user.provider === "local");
    },
    isAllowedFundingOrWithdraw(state) {
      return true; // Boolean(state.user?.funding || state.user?.withdraw)
    },
    riseId(state) {
      const role = state.user?.role?.type;
      if (state.user) {
        if (role === "client")
          return state.user?.activeSessionCompany != null
            ? state.user?.activeSessionCompany?.riseId
            : null;
        else if (role === "contractor") return state.user.riseId;
      }
      return null;
    },
    hasCompany(state) {
      return (
        state.user?.activeSessionCompany != null && state.user.companies.length
      );
    },
    isCompanyOwner(state) {
      return (
        state.user?.activeSessionCompany &&
        state.user?.activeSessionCompany?.owner?.id === state.user.id
      );
    },
    userOwnCompany(state) {
      return state.user.companies.find(
        (company) => company.owner.id === state.user.id
      );
    },
    registrationType(state) {
      return state.user?.registrationType;
    },
    needsOnboarding(state) {
      return state.user?.onboarding === null;
    },
  },

  actions: {
    async me(refresh = false) {
      if (this.user && !refresh) return this.user;
      const _user = await User.me();
      if (!_user?.email) return;

      this.user = _user;
      this.activeCompany = _user?.activeSessionCompany;
      const wallets = await getWallet();
      this.wallets = wallets;

      return _user;
    },
    async setRole(roleName) {
      const _user = await User.setRole(roleName);
      this.user = _user;
    },
    async updateUser(params) {
      await User.update(params, await this.$recaptcha("user"));
      this.activeCompany = this.user?.activeSessionCompany;
    },
    async createCompany(params) {
      const _company = await Company.create(params);
      const user = await User.me();
      this.user = user;
      if (this.user) this.user.activeSessionCompany = _company;

      this.activeCompany = _company;
    },
    async updateCompany(params) {
      const _company = await Company.update(params);
      if (this.user) this.user.activeSessionCompany = _company;

      this.activeCompany = _company;
    },
    async getWallet() {
      const wallets = await getWallet();
      this.wallets = wallets;
    },
    async getBalance() {
      if (this.riseId && validators.isAddress(this.riseId)) {
        const balance = await blockchainService.risePayBalanceOf(this.riseId);

        if (!balance?.data) return;
        const bigNumberBalance = BigNumber(balance.data);
        this.balance = bigNumberBalance.div(10 ** 6).toNumber();
      }
    },
  },
});
