<script>
import RiseSpinner from "./RiseSpinner.vue";

export default {
  components: {
    RiseSpinner,
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    text: {
      type: String,
      default: "Sample Text",
    },
    variant: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "base",
    },
    iconLeft: {
      type: [String, Array, Object],
      default: null,
    },
    iconRight: {
      type: [String, Array, Object],
      default: null,
    },
    iconSize: {
      type: String,
      default: "base",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variants: {
        primary:
          "bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] text-white hover:[background:linear-gradient(86.42deg,_#86B9E4_2.94%,_#808BDC_50.83%,_#808CDC_97.43%)] focus:ring focus:ring-blue-400",
        secondary:
          "bg-gradient-to-r from-[#5E59EC] to-[#1F2B38] text-white hover:[background:linear-gradient(90deg,_#7E7AEF_0%,_#4D5662_100%)] focus:ring focus:ring-primary-300",
        tertiary:
          "bg-gray-200 text-gray-900 hover:bg-gray-300 focus:ring focus:ring-gray-100",
        white:
          "bg-white text-primary-600 hover:bg-gray-200 focus:ring focus:ring-white",
        outline:
          "bg-white text-gray-800 ring-1 ring-inset ring-gray-200 hover:bg-gray-800 hover:ring-gray-800 hover:text-white disabled:bg-transparent disabled:text-gray-400 disabled:ring-gray-400",
        "outline-white":
          "bg-white/10 text-white ring-1 ring-inset ring-white/20 hover:bg-white hover:ring-white hover:text-gray-800",
        "outline-gradient":
          "bg-white text-gray-800 ring-1 ring-inset ring-primary-600 hover:bg-primary-800 hover:ring-gray-800",
        link: "bg-white text-primary-600 hover:text-primary-700",
        disabled:
          "bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring focus:ring-gray-100",
        danger:
          "bg-red-600 text-white hover:bg-red-700 focus:ring focus:ring-red-100",
      },
      sizes: {
        xs: "py-2 px-3 rounded-lg text-xs font-medium leading-[18px]",
        sm: "py-2 px-3 rounded-lg text-sm font-medium leading-[21px]",
        base: "py-[10px] px-5 rounded-lg text-sm font-medium leading-[21px]",
        l: "py-3 px-5 rounded-lg text-base font-medium leading-[24px]",
        xl: "py-[14px] px-6 rounded-lg text-base font-medium leading-[24px]",
      },
      iconSizes: {
        sm: "!w-[12px] !h-[12px]",
        base: "!w-[14px] !h-[14px]",
        l: "!w-[20px] !h-[20px]",
      },
    };
  },
  computed: {
    variantClasses() {
      if (this.disabled) return this.variants.disabled;

      return this.variants[this.variant];
    },
  },
};
</script>

<template>
  <button
    :type="type"
    class="group flex h-fit items-center justify-center outline-none transition-all"
    :class="[
      variantClasses,
      sizes[size],
      { 'w-full': fill, 'w-fit': !fill },
      { 'pointer-events-none': loading || disabled },
    ]"
    :disabled="disabled"
  >
    <RiseSpinner
      v-if="loading"
      class="mr-2 h-[14px] w-[14px] !fill-gray-800 !text-gray-900/25"
    />
    <div
      v-if="$slots['append-left'] && !loading"
      class="mr-2 flex items-center justify-center"
      :class="[iconSizes[iconSize]]"
    >
      <slot name="append-left" />
    </div>
    <font-awesome-icon
      v-if="iconLeft && !loading"
      class="mr-2"
      :class="[
        iconSizes[iconSize],
        { '!h-[12px] !w-[12px]': ['xs', 'sm'].includes(sizes[size]) },
      ]"
      :icon="iconLeft"
    />
    <div
      class="whitespace-nowrap"
      :class="{
        'bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] bg-clip-text text-transparent group-hover:text-white':
          variant === 'outline-gradient',
      }"
    >
      {{ text }}
    </div>
    <font-awesome-icon
      v-if="iconRight && !loading"
      class="ml-2"
      :class="[
        iconSizes[iconSize],
        { '!h-[12px] !w-[12px]': ['xs', 'sm'].includes(sizes[size]) },
      ]"
      :icon="iconRight"
    />
    <div
      v-if="$slots['append-right'] && !loading"
      class="ml-2 flex items-center justify-center"
      :class="[iconSizes[iconSize]]"
    >
      <slot name="append-right" />
    </div>
  </button>
</template>
