import { useCookies } from "@vueuse/integrations/useCookies";
import { useSessionStore } from "~~/store/session";
import { useTasksStore } from "~~/store/tasks";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const runtimeConfig = useRuntimeConfig();

  const clerkSessionStore = useClerkSessionStore();

  if (to.name === "sign-out") {
    await clerkSessionStore.signOut();
    return navigateTo({ path: "/sign-in" });
  }

  if (to.path === "/sign-in/callback") {
    if (clerkSessionStore.needSecondFactor) {
      return navigateTo("/sign-in/2fa");
    }

    return navigateTo(
      `/sign-up?error=${encodeURIComponent(
        "This account doesn't exist. Please create a new one."
      )}`
    );
  }

  if (to.path === "/") {
    if (runtimeConfig.public.isLocalDevelopment) return;

    return navigateTo("/dashboard");
  }

  if (to.meta.layout !== "app") return;

  const clerkUserStore = useClerkUserStore();

  const userCookie = useCookies([]);
  const userCookieValue = userCookie.get("rise-user");
  if (userCookieValue) {
    clerkUserStore.user = userCookieValue;
  }
  const serverHasSession = userCookieValue || clerkSessionStore.hasSession();

  if (!serverHasSession) {
    return navigateTo({ path: "/sign-in", query: { redirect: to.path } });
  }

  console.log({ serverHasSession, userCookieValue, from });
  clerkSessionStore.cleared = false;

  const sessionStore = useSessionStore();
  const tasksStore = useTasksStore();

  try {
    if (!sessionStore.user) {
      const _me = await sessionStore.me();
      await tasksStore.fetch();
      if (_me.countryBlacklisted) return navigateTo("/blocked");
    }

    if (sessionStore.needsOnboarding) {
      await clerkSessionStore.refreshSession();
      const sessionId = clerkSessionStore.sessionId;
      if (runtimeConfig.public.isLocalDevelopment) {
        location.href = `http://localhost:3001/onboarding/session?session_id=${sessionId}`;
        return;
      }

      return await navigateTo(
        `${runtimeConfig.public.ONBOARDING_URL}/onboarding/`,
        {
          external: true,
          replace: true,
        }
      );
    }

    return;
  } catch (e) {
    console.error(e);
    // this.$toastError("Your session was expired, please log in again.");
    // this.$sentryCaptureException(e);
    return navigateTo("/sign-out");
  }
});
