import { useLoaderStore } from "~/store/loader";

export default defineNuxtPlugin((nuxtApp) => {
  const loaderStore = useLoaderStore();
  const app = nuxtApp.vueApp;
  const loader = {
    show() {
      loaderStore.show();
    },
    hide() {
      loaderStore.hide();
    },
    showSignData(signData) {
      loaderStore.showSignData(signData);
    },
    hideSignData() {
      loaderStore.hideSignData();
    },
  };

  app.config.globalProperties.$loader = loader;
  app.provide("loader", loader);
});
