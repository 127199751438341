import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  if (runtimeConfig.public.isLocalDevelopment) return;

  const app = nuxtApp.vueApp;
  Sentry.init({
    app,
    dsn: "https://b1255068c37a4afaadd2b301ad996d67@o417911.ingest.sentry.io/6099288",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router
        ),
        tracingOrigins: [
          "localhost",
          "pay.riseworks.io",
          "pay.dev-riseworks.io",
          "pay.staging-riseworks.io",
          /^\//,
        ],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    ignoreErrors: [
      "Not connected to a wallet.",
      "Wallet not authorized",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Load failed",
      "TypeError: Importing a module script failed.",
      "Importing a module script failed.",
      "Load failed",
      "Failed to fetch dynamically imported module",
      "Unable to preload CSS",
      "Failed to update a ServiceWorker for scope",
      "FetchError",
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    environment: runtimeConfig.public.NODE_ENV || "dev",
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        );
      }
      return event;
    },
  });
  Sentry.attachErrorHandler(app, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ["activate", "mount", "update"],
  });

  app.provide("sentry", {
    setContext: (n, context) => Sentry.setContext(n, context),
    setUser: (user) => Sentry.setUser(user),
    setTag: (tagName, value) => Sentry.setTag(tagName, value),
    addBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
    captureException: (exception) => Sentry.captureException(exception),
  });

  return {
    provide: {
      sentrySetContext: (n, context) => Sentry.setContext(n, context),
      sentrySetUser: (user) => Sentry.setUser(user),
      sentrySetTag: (tagName, value) => Sentry.setTag(tagName, value),
      sentryAddBreadcrumb: (breadcrumb) => Sentry.addBreadcrumb(breadcrumb),
      sentryCaptureException: (exception) => Sentry.captureException(exception),
    },
  };
});
