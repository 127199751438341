import EINValidator from "ein-validator";
import SSNValidator from "ssn-validator";

export default {
  email: (str) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      `${str}`
    ),
  password: (str) => /^(?=.*[0-9])(?=.*[!@#$%^&*_+=]).{12,42}$/.test(`${str}`),
  longPassword: (str) =>
    /^(?=.*[0-9])(?=.*[!@#$%^&*_+=]).{18,42}$/.test(`${str}`),
  isDateOnly: (str) => /^\d{8}$/.test(`${str}`),
  isHyphenAlphanumeric: (str) => /^[a-zA-Z0-9-]+$/.test(`${str}`),
  isAddress: (str) => /^0x[a-fA-F0-9]{40}$/.test(`${str}`),
  isHash: (str) => /^0x[a-fA-F0-9]{64}$/.test(`${str}`),
  isHex: (str) => /^0x[a-fA-F0-9]+$/.test(`${str}`),
  isSig: (str) => /^0x[a-fA-F0-9]{130}$/.test(`${str}`),
  isVersion: (str) => /^\d+\.\d+\.\d+$/.test(`${str}`),
  isInteger: (str) => /^\d+$/.test(`${str}`),
  isCountryCode: (str) => /^[A-Z]{2}$/.test(`${str}`),
  isUrl: (str) =>
    !str?.length ||
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00A1-\uFFFF][a-z0-9\u00A1-\uFFFF_-]{0,62})?[a-z0-9\u00A1-\uFFFF]\.)+(?:[a-z\u00A1-\uFFFF]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      `${str}`
    ),
  required(value) {
    if (value === null) return false;
    if (value === undefined) return false;
    if (value === "") return false;
    return true;
  },
  ein: (str) => EINValidator.isValid(str),
  ssn: (str) => SSNValidator.isValid(str),
};
