import crypto from "node:crypto";
import BigNumber from "bignumber.js";

import { subYears } from "date-fns";
import { ethers } from "ethers";
import { BIRTHDATE_YEARS } from "./constants";

export function getGravatarUrl(email, size = 300) {
  const avatarHash = crypto
    .createHash("md5")
    .update(email.toLowerCase().trim())
    .digest("hex");
  return `https://www.gravatar.com/avatar/${avatarHash}?d=mp&s=${size}`;
}

export function pick(params, whitelist) {
  const _whitelist =
    typeof whitelist === "string" ? whitelist.split(",") : whitelist;
  return _whitelist.reduce((acc, val) => {
    acc[val] = params[val];
    return acc;
  }, {});
}

export function pickArray(array, whitelist) {
  const _whitelist =
    typeof whitelist === "string" ? whitelist.split(",") : whitelist;
  return array.filter((item) => _whitelist.includes(item));
}

export function popupCenter({ url, title, w, h, strOptions }) {
  // https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
  // EG popupCenter({url: 'http://www.xtf.dk', title: 'xtf', w: 900, h: 500});
  // Fixes dual-screen position                             Most browsers      Firefox

  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;

  // const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  // const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  // const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  // const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  // const systemZoom = width / window.screen.availWidth;
  // const left = (width - w) / 2 / systemZoom + dualScreenLeft
  // const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    `
    width=${w}, 
    height=${h}, 
    top=${top}, 
    left=${left}
    ${strOptions ? `,${strOptions}` : ""}
    `
  );

  if (!newWindow) {
    window.location.href = url;
  } else {
    if (window.focus) newWindow.focus();
    return newWindow;
  }
}

export function waitPopup(popup) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (popup.closed) {
        clearInterval(interval);
        resolve();
      }
    }, 200);
  });
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function maxBirthdate() {
  return subYears(new Date(), BIRTHDATE_YEARS);
}

export function chunk(array, n) {
  let i = 0;
  const chunks = [];
  while (i < array.length) {
    chunks.push(array.slice(i, i + n));
    i += n;
  }
  return chunks;
}

export const USDFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function _objToQuery(search, key, value) {
  if (
    ["bigint", "number", "string", "boolean"].includes(typeof value) ||
    value === null ||
    value === undefined
  ) {
    search.append(key, value);
  } else if (Array.isArray(value)) {
    value.forEach((v, idx) => {
      _objToQuery(search, `${key}[${idx}]`, v);
    });
  } else if (typeof value === "object") {
    Object.keys(value).map((vKey) => {
      _objToQuery(search, `${key}[${vKey}]`, value[vKey]);
    });
  }
}

export function objectToQueryStr(obj) {
  if (!obj) return "";
  const search = new URLSearchParams();
  Object.keys(obj).map((key) => {
    const value = obj[key];
    _objToQuery(search, key, value);
  });
  return search.toString();
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatUSD(amount) {
  return formatter.format(amount);
}

export function tokenToDecimal(token) {
  return Number.parseFloat(ethers.utils.formatUnits(token, 6)).toFixed(2);
}
export function decimalToToken(decimal) {
  return ethers.BigNumber.from(decimal).mul(1e6);
}
export function bigNumberDp(amount, decimalPlaces = 8) {
  const number = new BigNumber(amount);
  return new BigNumber(number.toFixed(decimalPlaces)).valueOf();
}

export function ABARoutingNumberIsValid(routingNumberToTest) {
  if (!routingNumberToTest) {
    // all 0's is technically a valid routing number, but it's inactive
    return false;
  }

  const routing = routingNumberToTest.toString();

  // gotta be 9  digits
  const match = routing.match("^\\d{9}$");
  if (!match) {
    return false;
  }

  // The first two digits of the nine digit RTN must be in the ranges 00 through 12, 21 through 32, 61 through 72, or 80.
  // https://en.wikipedia.org/wiki/Routing_transit_number
  const firstTwo = parseInt(routing.substring(0, 2));
  const firstTwoValid =
    (firstTwo >= 0 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32) ||
    (firstTwo >= 61 && firstTwo <= 72) ||
    firstTwo === 80;
  if (!firstTwoValid) return false;

  // this is the checksum
  // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
  const weights = [3, 7, 1];
  let sum = 0;
  for (let i = 0; i < 8; i++) sum += parseInt(routing[i]) * weights[i % 3];

  return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
}

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

export function getB2BInvite() {
  if (!localStorage) return null;
  const invite = localStorage.getItem("b2b_invite");
  if (invite) {
    try {
      return JSON.parse(invite);
    } catch (e) {
      console.log(`Failed to decode b2bInvite: ${invite}`, e);
      localStorage.removeItem("b2b_invite");
    }
  }
  return null;
}

export function unixTimestamp() {
  return Math.round(new Date().getTime() / 1000);
}

export default {
  getGravatarUrl,
  popupCenter,
  pick,
  pickArray,
  sleep,
  formatUSD,
  maxBirthdate,
  chunk,
  objectToQueryStr,
  tokenToDecimal,
  decimalToToken,
  bigNumberDp,
  ABARoutingNumberIsValid,
  getB2BInvite,
  unixTimestamp,
};
